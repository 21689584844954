import { createElement } from "react"
import { useEffect, useState, createContext, useContext } from "react"

/**
 * Two-pass render context
 *
 * The default value is true so if a library makes use of `useTwoPass()` but the application
 * does not use `<TwoPassRenderer>` it will always return true.
 */
const TwoPassContext = createContext(true)

export const TwoPassRenderer = ({ children }) => {
  const [isMounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  return createElement(TwoPassContext.Provider, { value: isMounted }, children)
}

/**
 * Returns false on first render of the page and ensures that the component re-renders an extra time if its first render was right part of hydration
 *
 * This creates a two-pass render that allows for rendering in a SSG friendly way but re-rendering later using browser state
 */
export default function useTwoPass() {
  return useContext(TwoPassContext)
}
